export const DAM_METAPROPERTIES = {
    CATEGORIE: "5088AA79-C05D-45DC-A244C2DF73052139",
    MODELLI: "FCA2155E-4C9D-4F58-899960876020EC2A",
    MATERIALI: "40E4135E-D6E5-422B-8257DF64A5F3F29A",
    TIPOLOGIE_MATERIALI: "6AC0BB37-0CCD-4F0E-BB0165AB36F4AFE2",
    COLORI: "404C7A0E-EFBC-4298-A30555109F63C348",
    VERSIONS: "49D3FC5B-8377-4FF9-A11E18EDACB28D98"
}

export const IMAGE_PROVIDER = 'ipx';

export const IMAGE_QUALITY = 90;

export enum EventTypes {
    LOGIN = "login",
    LOGOUT = "logout",
    PAGEVIEW = "page_view",
    CLICK = "click",
    SEARCH = "search",
    PRODUCTVIEW = "product_view",
    ADDTOCART = "add_to_cart",
    REMOVEFROMCART = "remove_from_cart",
    CHECKOUT = "checkout",
    FILTER = "filter",
    ADD_TO_DASHBOARD = "add_to_dashboard",
    REMOVE_FROM_DASHBOARD = "remove_from_dashboard",
    START_SESSION = "start_session",
    CLOSE_SESSION = "close_session",
}
